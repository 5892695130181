import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Speak from '../containers/Speak'

type ConferenceTalksProps = {}

const ConferenceTalks: React.FunctionComponent<
  ConferenceTalksProps
> = props => {
  return (
    <Layout>
      <SEO
        title="Conference talks"
        description="I speak at conferences and meetups around the world. Here are some of my talks that I've given"
      />

      <Speak />
    </Layout>
  )
}

export default ConferenceTalks
