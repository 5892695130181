import styled from 'styled-components'

export const ComingSoonWrapper = styled.div`
  position: absolute;
  right: -29px;
  top: -20px;
  width: 116px;
  transform: rotate(30deg);

  @media (max-width: 600px) {
    display: none;
  }
`

export const ComingSoonRibbon = styled.div`
  background-color: #e89694;
  color: hsl(0, 0%, 100%);
  margin: 20px 0;
  line-height: 34px;
  user-select: none;
  position: absolute;
  right: 0;
  padding: 0 10px;
  &:before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    border-style: solid;
    top: 5px;
    z-index: -10;
    border-color: #e89694 #e89694 #e89694 transparent;
    left: -25px;
    border-width: 17px;
  }
  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    border-style: solid;
    top: 5px;
    z-index: -10;
    border-color: #e89694 transparent #e89694 #e89694;
    right: -25px;
    border-width: 17px;
  }
`
