import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import SocialProfile from '../../../components/SocialProfile/SocialProfile'
import { SpeakTile } from './components/SpeakTile'
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin
} from 'react-icons/io'
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles
} from './style'

import SpeakerImg from './speaker.jpg'
const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/iamtylerwclark',
    tooltip: 'Twitter'
  }
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     avatar: file(absolutePath: { regex: "/speaker.jpg/" }) {
  //       childImageSharp {
  //         fluid(quality: 90) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     site {
  //       siteMetadata {
  //         author
  //         about
  //       }
  //     }
  //   }
  // `)

  const speakEvents = [
    {
      title: `ES8, ES9, ES10+… Let’s explore JavaScript’s exciting new features `,
      description: `JavaScript fatigue is a real thing. Not only are there constantly new tools, libraries, and framework versions, but the language itself is adding new features each year. Within the last five years, JavaScript has seen more additions than it had any of the previous years combined. A quick glance at the proposals within the TC39 github shows that there is no end in sight. It can be daunting to try to keep up. This talk is designed to help shine a light on some of the recent additions that you might not be aware of, that can be really useful in your everyday code. Topics include new data structures, new prototype methods on our favorite objects, asynchronous iteration, and new operators.`,
      link: '',
      date: 'April 16, 2020',
      location: 'Jackson, Mississippi',
      conferenceLink: 'https://magnoliajs.com/',
      conferenceName: 'MagnoliaJS',
      video: ''
    },
    {
      title: `Algorithms and JavaScript for Beginners`,
      description: `Bubble sort? Merge sort? Quicksort? Whether you're preparing for an interview or just trying to understand the code you're working with, this session is for those that want to get an introduction to algorithms. We'll review Big O Notation and work through binary search, quicksort, k-nearest neighbor, and breadth-first algorithms.`,
      link:
        'https://github.com/twclark0/Algorithms-JavaScript-Beginners/blob/master/deck.mdx',
      date: 'September 20, 2019',
      location: 'Salt Lake City, Utah',
      conferenceLink: 'https://conf.utahjs.com/',
      conferenceName: 'UtahJS',
      video:
        'https://www.youtube.com/watch?v=3VmGDjRrE10&list=PLAdIRk90T3WiSheqcofJYxMM9uJvfDVQ5&index=2&t=0s'
    },
    {
      title: `React to Web Workers`,
      description: `Imagine deploying your React app with a little army of helpers ready to catch offline requests, provide a performance boost by prefetching resources , and taking care of complex UI blocking functions.. Web workers give access to background threads within the browser that can run scripts and intercept requests. These tasks and so much more can be accomplished by messaging back and forth between web workers and your React app`,
      link:
        'https://github.com/twclark0/react-to-web-workers/blob/master/deck.mdx',
      date: 'June 21, 2019',
      location: 'Chicago, Illinois',
      conferenceLink: 'https://2019.reactloop.com/',
      conferenceName: 'React Loop',
      video: 'https://www.youtube.com/watch?v=pcYuOOe-kbw&feature=youtu.be'
    },
    {
      title: `Master the JavaScript Interview`,
      description: `Inspired by Eric Elliot’s series, “Master the JavaScript Interview” I will be reviewing tips and tricks to help get you your next JS job. We’ll go over recursion, closures, inheritance, function composition, functional programming, async, big O notation, and popular algorithms!`,
      link: 'https://github.com/twclark0/magnolia-talk/blob/master/deck.mdx',
      date: 'April 18, 2019',
      location: 'Jackson, Mississippi',
      conferenceLink: 'https://magnoliajs.com/',
      conferenceName: 'MagnoliaJS'
    },
    {
      title: `Syncing Component State Across Tabs with Service Workers`,
      description: `Service workers are on the rise and with a little creativity, we can use them in many different ways. One of which includes messaging react state between browser tabs. As interesting as this is, its actually really easy to get up and running! `,
      link: 'https://www.dropbox.com/s/v8sflrewri8vlat/puppeteer.pdf?dl=0',
      date: 'October 27, 2018',
      location: 'Las Vegas, Nevada',
      conferenceLink: 'https://conf.reactjs.org/',
      conferenceName: 'React Conf 2018',
      video:
        'https://www.youtube.com/watch?v=tq4vXRT7TU0&list=PLAdIRk90T3WiSheqcofJYxMM9uJvfDVQ5&index=3'
    },
    {
      title: `End to End tests with Google Puppeteer`,
      description: `End-to-end testing assures you of a few things: that all the integrated pieces of an application properly function, all those pieces work together as expected, and your tests include and simulate real-user scenarios — something unit and integration tests just don't do.`,
      link: 'https://www.dropbox.com/s/v8sflrewri8vlat/puppeteer.pdf?dl=0',
      date: 'September 16, 2018',
      location: 'Salt Lake City, Utah',
      conferenceLink: 'https://www.utahjs.com',
      conferenceName: 'UtahJS',
      video:
        'https://www.youtube.com/watch?v=xwiWqEkrtyQ&list=PLAdIRk90T3WiSheqcofJYxMM9uJvfDVQ5'
    },
    {
      title: 'Keep Your Sanity with Redux Sagas',
      description: `Redux is a strict framework. Whether you view that as a pro or a con, handling async actions can sometimes be a pain. Especially if you are using middleware such as Redux Thunk and have to chain promises. As an application grows, it's difficult to maintain and continuously test these actions. By the use of the ES6 generator function, Redux Sagas break up async actions into synchronous events. This makes it easier to test, chain actions that depend on the result of each other, and deserialize promises.`,
      link:
        'https://github.com/twclark0/redux-sagas-gh/tree/simple-saga-example?files=1',
      date: 'December 2nd, 2017',
      location: 'Berlin, Germany',
      conferenceLink: 'https://reactday.berlin/2017/',
      conferenceName: 'React Day Berlin',
      video:
        'https://www.youtube.com/watch?v=UnNLJvHKfSY&list=PLAdIRk90T3WiSheqcofJYxMM9uJvfDVQ5'
    }
  ]

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Conferences</h2>
        <p>
          I regularly try to speak at conferences and meetups. Here are some of
          the events that I've spoken at.
        </p>
      </AboutPageTitle>
      <AboutImage>
        {/* <Image
          fluid={Data.avatar.childImageSharp.fluid}
          alt="conference talk"
        /> */}
        <img src={SpeakerImg} alt="Tyler speaking at a conference" />
      </AboutImage>
      {speakEvents.map((props, i) => (
        <SpeakTile key={i} {...props} />
      ))}
      <AboutDetails>
        <h2>Wanna join the party?</h2>
        <p>
          Check out my newsletter or follow me on twitter to stay up to date on
          when my next talk is.
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
