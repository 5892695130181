import * as React from 'react'
import { ComingSoonRibbon, ComingSoonWrapper } from './style.tsx'

type ComingSoonProps = {}

export const ComingSoon: React.FunctionComponent<ComingSoonProps> = props => (
  <ComingSoonWrapper>
    <ComingSoonRibbon>Coming soon!</ComingSoonRibbon>
  </ComingSoonWrapper>
)
