import styled from 'styled-components'

export const SpeakTileH3 = styled.h3``

export const SpeakImg = styled.img`
  height: 30px;
  margin-right: 7px;
`
export const SpeakIconText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;
  img {
    margin-right: 5px;
  }
`
export const WrapperTileA = styled.a`
  display: flex;
  align-items: center;
  color: #000;
  margin-right: 35px;
`

export const SpeakTileWrapper = styled.div`
  margin: 35px 0;
  position: relative;
`
export const IconContainer = styled.div`
  display: flex;

  @media (max-width: 950px) {
    display: block;

    > div {
      margin: 5px 0;
    }
    > a {
      margin: 5px 0;
    }
  }
`
