import * as React from 'react'

import {
  SpeakTileH3,
  SpeakImg,
  SpeakIconText,
  WrapperTileA,
  SpeakTileWrapper,
  IconContainer
} from './style.tsx'
import Calendar from './icons/calendar.svg'
import Location from './icons/location.svg'
import SlideShow from './icons/slideshow.svg'
import Conference from './icons/political.svg'
import Video from './icons/video-camera.svg'
import { ComingSoon } from '../../../../components/ComingSoon'

type SpeakTileProps = {
  title: string
  description: string
  link: string
  date: string
  location: string
  conferenceName: string
  conferenceLink: string
  video: string
}

export const SpeakTile: React.FunctionComponent<SpeakTileProps> = props => {
  const talkSeconds = new Date(
    props.date
      .replace('th', '')
      .replace(',', '')
      .split(' ')
      .join('-')
  ).getTime()
  return (
    <SpeakTileWrapper>
      {Date.now() < talkSeconds ? <ComingSoon /> : null}
      <SpeakTileH3>{props.title}</SpeakTileH3>
      <p>{props.description}</p>
      <IconContainer>
        <WrapperTileA href={props.conferenceLink} alt="conference link">
          <SpeakImg src={Conference} alt="icon" />
          {props.conferenceName}
        </WrapperTileA>
        <WrapperTileA href={props.link} target="_blank">
          <SpeakImg src={SlideShow} alt="icon" />
          Slides
        </WrapperTileA>
        {props.video && (
          <WrapperTileA href={props.video} target="_blank">
            <SpeakImg src={Video} alt="icon" />
            Video
          </WrapperTileA>
        )}
        <SpeakIconText>
          <SpeakImg src={Calendar} alt="icon" />
          {props.date}
        </SpeakIconText>
        <SpeakIconText>
          <SpeakImg src={Location} alt="icon" />
          {props.location}
        </SpeakIconText>
      </IconContainer>
      <hr style={{ margin: '20px 0' }} />
    </SpeakTileWrapper>
  )
}
